const API_URL = "https://127.0.0.1:8000/api";

const getAuthHeaders = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return {
        'Authorization': `Bearer ${user?.token}`
    };
};

const handleError = async (response) => {
    const errorText = await response.text();
    throw new Error(`${response.status}: ${errorText}`);
};

const getOrdersCacheKey = () => `orders`;

const fetchOrdersFromCache = () => {
    const cacheKey = getOrdersCacheKey();
    const cachedData = localStorage.getItem(cacheKey);
    if (!cachedData) return null;

    const { expiry, data } = JSON.parse(cachedData);
    const now = new Date();

    if (now.getTime() > expiry) {
        localStorage.removeItem(cacheKey);
        return null;
    }

    return data;
};

const cacheOrders = (data) => {
    const cacheKey = getOrdersCacheKey();
    const expiry = new Date().getTime() + (30 * 60 * 1000); // Cache for 5 minutes
    const cachedData = { expiry, data };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};


export const orderService = {
    fetchOrders: async () => {
        // Vérifier d'abord le cache
        const cachedData = fetchOrdersFromCache();
        if (cachedData) return cachedData;

        const response = await fetch(`${API_URL}/orders`, {
            headers: getAuthHeaders()
        });
        if (!response.ok) {
            await handleError(response);
        }
        const data = await response.json();

        cacheOrders(data);

        return data;
    },

    fetchOrderById: async (orderId) => {
        try {
            const response = await fetch(`${API_URL}/orders/${orderId}?expand=customOptions`, {
                headers: getAuthHeaders(),
            });
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
            const order = await response.json();
    
            // Traitement pour extraire les options de personnalisation
            order.orderItems.forEach(item => {
                if (item.product_option && item.product_option.extension_attributes && item.product_option.extension_attributes.custom_options) {
                    item.customOptions = item.product_option.extension_attributes.custom_options;
                }
            });
    
            return order;
        } catch (error) {
            console.error('Erreur lors de la récupération des détails de la commande:', error);
            throw error; 
        }
    },

    createDuplicateOrder: async (modifiedOrderData) => {
        const response = await fetch(`${API_URL}/orders`, {
            method: 'POST',
            headers: { ...getAuthHeaders(), 'Content-Type': 'application/json' },
            body: JSON.stringify(modifiedOrderData)
        });
    
        if (!response.ok) {
            const error = await response.json();
            console.error('Erreur lors de la création de la commande:', error);
            throw new Error(`Erreur lors de la création: ${error.message}`);
        }
    
        const newOrder = await response.json();

        localStorage.removeItem(getOrdersCacheKey());
    
        return newOrder;
    },
    

    deleteOrder: async (orderId) => {
        const id = orderId.split('/').pop(); 
        const url = `${API_URL}/orders/${id}`; 
    
        const response = await fetch(url, {
            method: 'DELETE',
            headers: getAuthHeaders()
        });
    
        if (!response.ok) {
            await handleError(response);
        }
        return true;
    }
    
};
