import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";
import { productService } from '../api/productService';

const ProductDetail = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [attributeOptions, setAttributeOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [contentOrder, setContentOrder] = useState('');
    const [picking, setPicking] = useState('');

    useEffect(() => {
        const fetchProductDetails = async () => {
            setIsLoading(true);
            try {
                const fetchedProduct = await productService.fetchProduct(productId);
                setProduct(fetchedProduct);
                setContentOrder(fetchedProduct.contentOrder || '');
                setPicking(fetchedProduct.picking || '');

                const fetchedAttributeOptions = await productService.fetchAttributeOptions(productId);
                setAttributeOptions(fetchedAttributeOptions);
            } catch (error) {
                console.error('Error fetching product and attributes:', error);
            }
            setIsLoading(false);
        };
        fetchProductDetails();
    }, [productId]);

    const saveChanges = async () => {
        const validContentOrders = ['stock', 'factory', 'print'];
    
        if (!validContentOrders.includes(contentOrder)) {
            alert(`La valeur de contentOrder doit être l'une des suivantes: ${validContentOrders.join(', ')}`);
            return;
        }
    
        const updates = {
            contentOrder,
            picking,
        };
    
        try {
            const updatedProduct = await productService.updateProduct(productId, updates);
            console.log('Produit mis à jour avec succès', updatedProduct);
            alert('Produit mis à jour avec succès!');
        } catch (error) {
            console.error('Erreur lors de la mise à jour du produit:', error);
            alert("Erreur lors de la mise à jour des détails du produit.");
        }
    };

    const findAttributeValue = (attributeCode) => {
        const attribute = product.customAttributes.find(attr => attr.attribute_code === attributeCode);
        if (!attribute) return null;
    
        // Trouver l'option d'attribut correspondante en utilisant la valeur de l'attribut
        const option = attributeOptions.find(opt => opt.value === attribute.value);
        
        // Retourner le label de l'option, sinon la valeur brute de l'attribut
        return option ? option.label : attribute.value;
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
                <h2 className="text-center text-xl font-semibold ml-4">Chargement...</h2>
            </div>
        );
    }

    if (!product) {
        return <div className="text-center mt-10 text-xl text-gray-700">Produit non trouvé</div>;
    }

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-4xl font-bold mb-6" style={{ color: '#06748c' }}>{product.name}</h1>
            <Link to="/produit" className="flex items-center text-blue-500 hover:text-blue-700 font-semibold mb-8">
                <FaArrowLeft className="mr-2" />Retourner à la liste des produits
            </Link>
            <div className="bg-white shadow rounded-lg p-6">
                <div className="mb-4 text-lg font-semibold text-gray-700">
                    <span className="text-black">Prix:</span> {product.price} €
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <p className="mb-2"><strong>SKU:</strong> {product.sku}</p>
                    {[
                        'universal_unique_product_id',
                        'universal_product_group',
                        'brand',
                        'universal_product_lang',
                        'universal_product_licence',
                        'universal_product_shape',
                        'universal_product_size',
                        'universal_product_type',
                        'configurable_couleur',
                        'configurable_forme',
                        'configurable_langue',
                        'configurable_modele_bois',
                        'configurable_modele_decoration',
                        'configurable_modele_objet',
                        'configurable_modele_poster',
                        'configurable_modele_stickers',
                        'configurable_modele_sublimation',
                        'configurable_modele_textile',
                        'configurable_taille',
                        'labelmaker_label_shape',
                        'labelmaker_label_size',
                        'labelmaker_profil_id',
                        'labelmaker_profil_personalization_id',
                        'labelmaker_support',
                        'misc_product_class',
                        'packing_type',
                        'shipping_exception',
                        'thematique'
                    ].map(attributeCode => {
                        const value = findAttributeValue(attributeCode);
                        return value && (
                            <p className="mb-2" key={attributeCode}>
                                <strong className="capitalize" style={{ color: '#06748c' }}>
                                    {attributeCode.replace(/_/g, ' ')}:
                                </strong> 
                                <span className="text-black"> {value}</span>
                            </p>
                        );
                    })}
                </div>
                {/* Picking Textarea */}
                <div className="mb-4">
                    <label htmlFor="picking" className="block text-sm font-medium text-gray-700">
                        Picking
                    </label>
                    <textarea
                        id="picking"
                        value={picking}
                        onChange={(e) => setPicking(e.target.value)}
                        rows="4"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    ></textarea>
                </div>

                {/* Save Changes Button */}
                <div className="flex justify-end mt-4">
                    <button
                        onClick={saveChanges}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Enregistrer les modifications
                    </button>
                </div>
            </div>            
        </div>
    );
};

export default ProductDetail;
