import React, { useState, useEffect } from 'react';
import { userService } from '../api/userService';
import { motion, AnimatePresence } from 'framer-motion';
import '../index.css';

const UserEditForm = ({ onSave, user }) => {
    const [email, setEmail] = useState(user.email);
    const [password, setPassword] = useState(''); // Ajout du champ de mot de passe
    const [isActive, setIsActive] = useState(user.active);
    const [role, setRole] = useState(user.roles[0] || 'ROLE_USER');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);

    useEffect(() => {
        setEmail(user.email);
        setIsActive(user.active);
        setRole(user.roles[0] || 'ROLE_USER');
    }, [user]);

    const formVariants = {
        hidden: {
            opacity: 0,
            y: -20,
            transition: {
                duration: 0.5
            }
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5
            }
        },
        exit: {
            opacity: 0,
            y: 20,
            transition: {
                duration: 0.5
            }
        }
    };

    const spinnerVariants = {
        loading: { rotate: 360, transition: { repeat: Infinity, duration: 1 } }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        const userId = user && user['@id'];
    
        if (!userId) {
            setError("L'ID de l'utilisateur est indéfini.");
            setLoading(false);
            return;
        }
        
        const userData = {
            '@context': user['@context'],
            '@id': userId,
            '@type': user['@type'],
            email,
            roles: [role],
            active: isActive,
            password: password || undefined // Utilisez le mot de passe seulement s'il est défini
        };
        
        try {
            await userService.patchUser(userId, userData);
            onSave();
            setUpdateSuccess(true);
            setTimeout(() => {
                window.location.reload();
            }, 500); // Temps pour permettre l'animation de sortie
        } catch (error) {
            setError("Erreur lors de la sauvegarde de l'utilisateur: " + error.message);
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <AnimatePresence>
            {updateSuccess || (
                <motion.form 
                    onSubmit={handleSubmit} 
                    className="mt-4"
                    variants={formVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    >
                    {error && <div className="mb-4 text-red-500">{error}</div>}
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">Mot de passe</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <div className="mb-4 flex items-center">
                        <input
                            type="checkbox"
                            id="isActive"
                            checked={isActive}
                            onChange={e => setIsActive(e.target.checked)}
                            className="form-checkbox h-5 w-5 "
                            style={{ Color: '#272860' }}
                        />
                        <label htmlFor="isActive" className="ml-2 text-gray-700 text-sm font-bold">Actif</label>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="role" className="block text-gray-700 text-sm font-bold mb-2">Rôle</label>
                        <select
                            id="role"
                            value={role}
                            onChange={e => setRole(e.target.value)}
                            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                        >
                            <option value="ROLE_USER">Utilisateur</option>
                            <option value="ROLE_ADMIN">Administrateur</option>
                        </select>
                    </div>
                    <button
                        type="submit"
                        className="bg-06748c hover:bg-06748c-darker text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        disabled={loading}
                    >
                        {loading ? (
                            <motion.div className="spinner" variants={spinnerVariants} animate="loading"></motion.div>
                        ) : 'Enregistrer'}
                    </button>
                </motion.form>
            )}
        </AnimatePresence>
    );
};

export default UserEditForm;