import React, { useState } from 'react';
import { FaTrash, FaEdit, FaCheck, FaTimes } from "react-icons/fa";
import { motion } from 'framer-motion';

const Tables = ({ users, onEditUser, onDeleteUser }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10); // Nombre d'utilisateurs par page
    const totalPages = Math.ceil(users.length / usersPerPage);

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

    const nextPage = () => setCurrentPage(prev => (prev < totalPages ? prev + 1 : prev));
    const prevPage = () => setCurrentPage(prev => (prev > 1 ? prev - 1 : prev));

    const handleDeleteUser = (userId) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?")) {
            onDeleteUser(userId);
        }
    };

    const rowVariants = {
        hidden: { opacity: 0, x: -100 },
        visible: { 
            opacity: 1, 
            x: 0,
            transition: {
                duration: 0.8
            }
        }
    };

    return (
        <div className="container mx-auto p-4">
            <div className="bg-white shadow rounded-lg overflow-x-auto">
                <table className="min-w-full whitespace-no-wrap">
                    <thead style={{ backgroundColor: '#272860' }}>
                        <tr className="text-white text-center">
                            <th className="px-5 py-2">Email</th>
                            <th className="px-4 py-2">Actif</th>
                            <th className="px-4 py-2">Rôle</th>
                            <th className="px-4 py-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {currentUsers.map((user, index) => (
                            <motion.tr 
                                key={`${user.id}_${index}`}
                                variants={rowVariants}
                                initial="hidden"
                                animate="visible"
                                custom={index}
                            >
                                <td className="px-6 py-4 text-center">{user.email}</td>
                                <td className="px-6 py-4 text-center">
                                    {user.active ? <FaCheck className="text-green-500 mx-auto" style={{color:'#53c6b6'}} /> : <FaTimes className="text-red-500 mx-auto" />}
                                </td>
                                <td className="px-6 py-4 text-center">
                                    {user.roles.includes('ROLE_ADMIN') ? 'Administrateur' : 'Utilisateur'}
                                </td>
                                <td className="px-6 py-4 text-center">
                                    <FaEdit className="inline mr-2 cursor-pointer"
                                    style={{ color: '#272860'}}
                                     onClick={() => onEditUser(user)} />
                                    <FaTrash className="inline cursor-pointer text-red-700"
                                    style={{ color: '#ff76a6'}}
                                     onClick={() => handleDeleteUser(user['@id'])} />
                                </td>
                            </motion.tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination flex justify-center mt-4">
                <button onClick={prevPage} disabled={currentPage === 1} className="pagination-nav mr-2">Précédent</button>
                <button onClick={nextPage} disabled={currentPage === totalPages} className="pagination-nav">Suivant</button>
            </div>
        </div>
    );
};

export default Tables;
