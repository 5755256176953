const API_URL = "https://127.0.0.1:8000/api";

export async function authenticate(username, password) {
    try {
        const response = await fetch(`${API_URL}/login_check`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password }),
        });

        if (!response.ok) {
            throw new Error('Échec de la connexion');
        }

        const data = await response.json();
        localStorage.setItem('user', JSON.stringify({ token: data.token, role: data.role })); 
        return data;
    } catch (error) {
        throw error;
    }
}

export function logoutUser() {
    localStorage.removeItem('token');
}