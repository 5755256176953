import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import UserPage from './pages/UserPage';
import ProductPage from './pages/ProductPage';
import OrderPage from './pages/OrderPage';
import Sidebar from './components/Sidebar';
import OrderDetails from './components/OrderDetails'
import ProductDetail from './components/ProductDetail';
import { logoutUser } from './api/auth';
import './index.css'

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('token') ? true : false);    
    const ProtectedRoute = ({ children }) => {
        const isAuthenticated = localStorage.getItem('token') ? true : false;
        return isAuthenticated ? children : <Navigate to="/connexion" />;
    };

   useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
        }
    }, []);

    const handleLoginSuccess = (data) => {
        setIsAuthenticated(true);
        localStorage.setItem('token', data.token);
    };

    const handleLogout = () => {
        logoutUser();
        setIsAuthenticated(false);
        window.location.href = '/connexion';
    };

    return (
        <Router>
            {!isAuthenticated ? (
                <Routes>
                    <Route path="/connexion" element={<LoginPage onLoginSuccess={handleLoginSuccess} />} />
                    <Route path="*" element={<Navigate to="/connexion" />} />
                </Routes>
            ) : (
                <div className="flex h-screen">
                    <Sidebar onLogout={handleLogout} />
                    <div className="flex-1">
                        <Routes>
                            <Route path="/accueil" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
                            <Route path="/utilisateur" element={<UserPage />} />
                            <Route path="/produit" element={<ProductPage />} />
                            <Route path="/product/:productId" element={<ProductDetail />} />
                            <Route path="/commande" element={<OrderPage />} />
                            <Route path="/commande/:commandeId" element={<OrderDetails />} />
                            <Route path="/accueil" element={<Navigate replace to="/accueil" />} />
                        </Routes>
                    </div>
                </div>
            )}
        </Router>
    );
}


export default App;
