import React, { useState } from 'react';
import { downloadExport } from '../api/exportService';

const ExportButtons = () => {
    const [channel, setChannel] = useState('');

    const handleDownload = async (type) => {
        try {
            const blob = await downloadExport(type, [], ';', channel);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${type}_export.csv`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Failed to download the file:', error);
        }
    };

    return (
        <div className="flex flex-col items-center space-y-4">
            <select
                value={channel}
                onChange={(e) => setChannel(e.target.value)}
                className="px-4 py-2 border rounded"
            >
                <option value="">Sélectionner un canal</option>
                <option value="Amazon">Amazon</option>
                <option value="Etsy">Etsy</option>
                <option value="Magento-Ludilabel-fr_FR">Magento</option>
            </select>
            <button
                onClick={() => handleDownload('accounting')}
                className="px-4 py-2 bg-blue-500 text-white rounded shadow hover:bg-blue-700"
            >
                Télécharger l'export comptable
            </button>
            <button
                onClick={() => handleDownload('royalties')}
                className="px-4 py-2 bg-green-500 text-white rounded shadow hover:bg-green-700"
            >
                Télécharger l'export des royalties
            </button>
        </div>
    );
};

export default ExportButtons;
