import React, { useState, useEffect } from 'react';
import { userService } from '../api/userService';
import ReactModal from 'react-modal';
import { FaUserPlus } from 'react-icons/fa';
import Tables from '../components/Tables';
import UserForm from '../components/UserForm';
import UserEditForm from '../components/UserEditForm';
import '../index.css';

ReactModal.setAppElement('#root');

function UserPage() {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await userService.getAllUsers();
            if (response['hydra:member'] && Array.isArray(response['hydra:member'])) {
                setUsers(response['hydra:member']);
            } else {
                throw new Error('Format de réponse inattendu');
            }
        } catch (error) {
            console.error('Fetch users failed:', error);
            setUsers([]);
        }
    };

    const handleSaveUser = async (user) => {
        try {
            if (isEditMode) {
                await userService.patchUser(selectedUser.id, user);
            } else {
                await userService.createUser(user);
            }
            fetchUsers();
            closeForm();
        } catch (error) {
            console.error('Save user failed:', error);
        }
    };

    const handleEditUser = (user) => {
        setSelectedUser(user);
        setIsEditMode(true);
        setShowForm(true);
    };

    const handleDeleteUser = async (userId) => {
        try {
            await userService.deleteUser(userId);
            fetchUsers();
        } catch (error) {
            console.error('Delete user failed:', error);
        }
    };

    const closeForm = () => {
        setSelectedUser(null);
        setIsEditMode(false);
        setShowForm(false);
    };

    const handleAddUser = () => {
        setSelectedUser({ isActive: true, roles: ['ROLE_USER'] });
        setIsEditMode(false);
        setShowForm(true);
    };

    return (
        <div className="container mx-auto px-6 py-8">
            <h3 className="font-bold text-xl sm:text-2xl md:text-3xl " style={{ color: '#272860'}}>Gestion des Utilisateurs</h3>
            
            <div className="flex justify-end mb-4">
                <button 
                    onClick={handleAddUser} 
                    className="hover:bg-gray-600 text-white font-bold py-2 px-4 rounded flex items-center shadow transition duration-300 ease-in-out"
                    style={{ backgroundColor: '#272860' }} 
                >
                    <FaUserPlus className="text-lg mr-2" />
                    Ajouter un utilisateur
                </button>
            </div>

            <Tables 
                users={users} 
                onEditUser={handleEditUser} 
                onDeleteUser={handleDeleteUser} 
            />

            <ReactModal 
                isOpen={showForm}
                onRequestClose={closeForm}
                className="modal"
                overlayClassName="overlay"
            >
                {isEditMode ? (
                    <UserEditForm
                        user={selectedUser}
                        onSave={handleSaveUser}
                    />
                ) : (
                    <UserForm
                        onSave={handleSaveUser}
                    />
                )}
            </ReactModal>
        </div>
    );
}

export default UserPage;
