const API_URL = "https://127.0.0.1:8000/api";

const getAuthHeaders = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user?.token}`
    };
};

const handleError = async (response) => {
    const errorText = await response.text();
    throw new Error(`${response.status}: ${errorText}`);
};

export const userService = {
    getAllUsers: async () => {
        const response = await fetch(`${API_URL}/users`, {
            headers: getAuthHeaders()
        });
        if (!response.ok) {
            await handleError(response);
        }
        return await response.json();
    },

    createUser: async (user) => {
        const response = await fetch(`${API_URL}/users`, {
            method: 'POST',
            headers: getAuthHeaders(),
            body: JSON.stringify(user)
        });
        if (!response.ok) {
            await handleError(response);
        }
        return await response.json();
    },

    patchUser: async (userId, user) => {
        const id = userId.split('/').pop(); 
        const url = `${API_URL}/users/${id}`;
        console.log("URL for PATCH request:", url);
    
        const response = await fetch(url, {
            method: 'PATCH',
            headers: {
                ...getAuthHeaders(),
                'Content-Type': 'application/merge-patch+json'
            },
            body: JSON.stringify(user)
        });
    
        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error in PATCH request:', errorText);
            throw new Error(`${response.status}: ${errorText}`);
        }
    
        return await response.json();
    },

    deleteUser: async (userId) => {
        const id = userId.split('/').pop(); 
        const url = `${API_URL}/users/${id}`;
        console.log("URL for DELETE request:", url);
    
        const response = await fetch(url, {
            method: 'DELETE',
            headers: getAuthHeaders()
        });
    
        if (!response.ok) {
            await handleError(response);
        }
        return true; 
    }
};
