import React, { useState, useEffect } from 'react';
import { userService } from '../api/userService';
import { motion, AnimatePresence } from 'framer-motion';
import '../index.css'

const UserForm = ({ onSave, user }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [role, setRole] = useState('ROLE_USER');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isEditing = user && user.id;

    useEffect(() => {
        if (isEditing) {
            setIsActive(user.isActive);
            setRole(user.roles[0] || 'ROLE_USER');
        } else {
            resetForm();
        }
    }, [user, isEditing]);

    const formVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 20 }
    };

    const loadingVariants = {
        loading: { rotate: 360, transition: { repeat: Infinity, duration: 1 } }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        let userData;
        if (isEditing) {
            userData = { roles: [role], active: isActive };
        } else {
            if (!/\S+@\S+\.\S+/.test(email)) {
                alert('Email non valide');
                setIsSubmitting(false);
                return;
            }
            userData = { email, password, roles: [role], active: isActive };
        }

        try {
            if (isEditing) {
                await userService.patchUser(user.id, userData);
            } else {
                await userService.createUser(userData);
            }
            onSave();
        } catch (error) {
            console.error('Save user failed:', error);
        } finally {
            setIsSubmitting(false);
            resetForm();
        }
    };

    const resetForm = () => {
        setEmail('');
        setPassword('');
        setIsActive(true);
        setRole('ROLE_USER');
    };

    return (
        <AnimatePresence>
            {!isSubmitting ? (
                <motion.form 
                    onSubmit={handleSubmit}
                    className="mt-4"
                    variants={formVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    {!isEditing && (
                        <>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                                <input 
                                    type="email" 
                                    id="email" 
                                    value={email} 
                                    onChange={e => setEmail(e.target.value)} 
                                    required 
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                                />
                            </div>
                            <div className="mb-4">
    <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">Mot de passe</label>
    <input 
        type="password" 
        id="password" 
        value={password} 
        onChange={e => setPassword(e.target.value)} 
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
    />
</div>
                        </>
                    )}
                    <div className="mb-4 flex items-center">
                        <input 
                            type="checkbox" 
                            id="isActive" 
                            checked={isActive} 
                            onChange={e => setIsActive(e.target.checked)} 
                            className="form-checkbox h-5 w-5 " 
                            style={{ color: '#272860' }}
                        />
                        <label htmlFor="isActive" className="ml-2 text-gray-700 text-sm font-bold">Actif</label>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="role" className="block text-gray-700 text-sm font-bold mb-2">Rôle</label>
                        <select 
                            id="role" 
                            value={role} 
                            onChange={e => setRole(e.target.value)}
                            className="block appearance-none w-full bg-white border border-gray-400 hover:border-#06748c px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:border-#06748c"
                        >
                            <option value="ROLE_USER">Utilisateur</option>
                            <option value="ROLE_ADMIN">Administrateur</option>
                        </select>
                    </div>
                    <button 
                        type="submit" 
                        className="bg-06748c hover:bg-06748c-darker text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        {isSubmitting ? 'Enregistrement...' : 'Enregistrer'}
                    </button>
                </motion.form>
            ) : (
        <motion.div 
            className="loading-spinner"
            variants={loadingVariants}
            animate="loading"
        >
            <div className="spinner"></div>
        </motion.div>          
    )}
        </AnimatePresence>
    );
};

export default UserForm;
