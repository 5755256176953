import React from 'react';
import ExportButtons from '../components/ExportButtons';

const HomePage = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <h1 className="text-3xl font-bold mb-8">Exportations de Données</h1>
            <ExportButtons />
        </div>
    );
};

export default HomePage;
