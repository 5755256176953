import React, { useState, useEffect } from 'react';
import ProductTable from '../components/ProductTable';
import { productService } from '../api/productService';

function ProductPage() {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const fetchedProducts = await productService.fetchProducts();
                setProducts(fetchedProducts);
            } catch (error) {
                console.error('Erreur lors de la récupération des produits:', error);
            }
        };
        fetchProducts();
    }, []);

    return (
        <div className="container mx-auto p-4">
            <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                <h1 className="font-bold text-xl sm:text-2xl md:text-3xl text-gray-700" style={{position: 'relative', zIndex: '1'}}>Liste des Produits</h1>
            </div>
            <ProductTable products={products} />
        </div>
    );
}

export default ProductPage;
