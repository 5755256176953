import React, { useState, useEffect, useCallback } from 'react';
import { FaTrash, FaEye, FaSpinner, FaSearch } from "react-icons/fa";
import { motion, useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { orderService } from '../api/orderService';


const GradientBackgroundAnimation = () => {
    const animationControl = useAnimation();
  
    useEffect(() => {
      const sequence = async () => {
        await animationControl.start({
          background: [
            `linear-gradient(120deg, #fbfbfb 0%, #fbfbfb 100%)`,
          ],
        }, {
          duration: 30,
          loop: Infinity,
          ease: "linear"
        });
      };
  
      sequence();
    }, [animationControl]);
  
    return (
      <motion.div
        className="fixed top-0 left-0 w-full h-full z-0"
        style={{ pointerEvents: 'none' }}
        animate={animationControl}
      />
    );
  };
  



const OrderTable = ({ onEditOrder }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [ordersPerPage] = useState(7);
    const [allOrders, setAllOrders] = useState([]);
    const [displayedOrders, setDisplayedOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    //Etats pour la recherche
    const [searchEmail, setSearchEmail] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const [searchId, setSearchId] = useState('');

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.1 } }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: { y: 0, opacity: 1 }
    };
    const loadOrders = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await orderService.fetchOrders();
            console.log('Orders Data:', response); // Log pour vérifier les données
            let filteredOrders = response['hydra:member'];
            if (searchEmail) {
                filteredOrders = filteredOrders.filter(order => order.customerEmail.includes(searchEmail));
            }
            if (searchId) {
                filteredOrders = filteredOrders.filter(order => order.incrementId.includes(searchId));
            }
            if (searchStatus) {
                filteredOrders = filteredOrders.filter(order => order.status === searchStatus);
            }
    
            setAllOrders(filteredOrders);
            setDisplayedOrders(filteredOrders.slice(0, ordersPerPage));
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
        setIsLoading(false);
    }, [searchEmail, searchId, searchStatus, ordersPerPage]);

    useEffect(() => {
        loadOrders();
    }, [loadOrders]);

    useEffect(() => {
        const indexOfLastOrder = currentPage * ordersPerPage;
        const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
        setDisplayedOrders(allOrders.slice(indexOfFirstOrder, indexOfLastOrder));
    }, [currentPage, allOrders, ordersPerPage]);

    const totalPages = Math.ceil(allOrders.length / ordersPerPage);

    const handleDeleteOrder = async (orderId) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cette commande ?")) {
            setIsLoading(true);
            try {
                await orderService.deleteOrder(orderId);
                setTimeout(() => {
                    setAllOrders(allOrders.filter(order => order['@id'] !== orderId));
                    setIsLoading(false);
                }, 1000); // Délai de 1 seconde
            } catch (error) {
                console.error('Erreur lors de la suppression de la commande:', error);
                setIsLoading(false);
            }
        }
    };

    const showDetails = (order) => {
        navigate(`/commande/${order['@id'].split('/').pop()}`);
    };


    // Gestion des changements des champs de recherche
    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        if (name === 'searchEmail') {
            setSearchEmail(value);
        } else if (name === 'searchStatus') {
            setSearchStatus(value);
        } else if (name === 'searchId') {
            setSearchId(value);
        }
    };

    // Soumission du formulaire de recherche
    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        loadOrders();
    };

    return (
        <>
        <GradientBackgroundAnimation />
        <div className="container mx-auto p-4z-10" style={{ position: 'relative', zIndex: '1' }}>
            {/* Formulaire de recherche */}
            <form onSubmit={handleSearchSubmit} className="mb-4">
                <input
                    type="text"
                    name="searchEmail"
                    value={searchEmail} 
                    onChange={handleSearchChange}

                    placeholder="Rechercher par email"
                    className="appearance-none border rounded border p-2 mr-2  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <input
                    type="text"
                    name="searchId"
                    value={searchId}
                    onChange={handleSearchChange}
                    placeholder="Rechercher par ID"
                    className="appearance-none border rounded border p-2 mr-2  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <select
                    name="searchStatus"
                    value={searchStatus}
                    onChange={handleSearchChange}
                    className="appearance-none border rounded border p-2 mr-2  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="">Sélectionner un statut</option>
                    <option value="new">Nouveau</option>
                    <option value="processing">En cours</option>
                    <option value="completed">Terminé</option>
                </select>
                <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"style={{ backgroundColor: '#272860'}}>
                    <FaSearch className="inline mr-2"/>Rechercher
                </button>
            </form>
                {isLoading ? (
                    <div className="flex justify-center items-center">
                        <FaSpinner className="animate-spin h-8 w-8 mr-3" />
                        Chargement...
                    </div>
                ) : (
                    <motion.div
                        className="bg-white shadow rounded-lg overflow-x-auto"
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                    >
                        <table className="min-w-full whitespace-no-wrap">
                            <thead style={{ backgroundColor: '#272860' }}>
                                <tr className="text-white text-center">
                                    <th className="px-4 py-2">Date</th>
                                    <th className="px-4 py-2">Email</th>
                                    <th className="px-4 py-2">Total</th>
                                    <th className='px-4 py-2'>Id Boutique</th>
                                    <th className='px-4 py-2'>Canal</th>
                                    <th className='px-4 py-2'>Pays</th>
                                    <th className="px-4 py-2">Statut</th>
                                    <th className="px-4 py-2">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedOrders.map((order, index) => (
                                    <motion.tr
                                        key={order['@id']}
                                        variants={itemVariants}
                                        className="text-center border-b"
                                    >
                                        <td className="px-6 py-4">{order.createdAt}</td>
                                        <td className="px-6 py-4">{order.customerEmail}</td>
                                        <td className="px-6 py-4">{order.baseGrandTotal} €</td>
                                        <td className="px-6 py-4">{order.storeId}</td>
                                        <td className="px-6 py-4">{order.channel.code}</td>
                                        <td className="px-6 py-4">{order.billingAddress.countryId}</td>
                                        <td className="px-6 py-4">{order.status}</td>
                                        <td className="px-6 py-4">
                                        <FaEye
                                            className="inline mr-2 cursor-pointer"
                                            style={{ backgroundColor: '#272860', padding: '2px', borderRadius: '50%', color: 'white' }}
                                            onClick={() => showDetails(order)}
                                        />                                           
                                            <FaTrash className="inline cursor-pointer "
                                            style={{ color: '#ff76a6'}}
                                            onClick={() => handleDeleteOrder(order['@id'])} />
                                        </td>
                                        </motion.tr>
                                ))}
                            </tbody>
                        </table>
                    </motion.div>
                )}
                    <div className="pagination flex justify-center mt-4">
                        <button onClick={() => setCurrentPage(current => Math.max(current - 1, 1))} disabled={currentPage === 1} className="pagination-nav mr-2">Précédent</button>
                        <button onClick={() => setCurrentPage(current => Math.min(current + 1, totalPages))} disabled={currentPage === totalPages} className="pagination-nav">Suivant</button>
                        </div>
            </div>
        </>
    );
};

export default OrderTable;