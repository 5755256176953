import React, { useState, useEffect } from 'react';
import OrderTable from '../components/OrderTable';
import { orderService } from '../api/orderService';

function OrderPage() {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await orderService.fetchOrders();
                if (response && Array.isArray(response['hydra:member'])) {
                    setOrders(response['hydra:member']);
                } else {
                    console.error('La réponse n\'est pas un tableau:', response);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des commandes:', error);
            }
        };
        fetchOrders();
    }, []);

    return (
        <div className="container mx-auto p-4">
            <h1 className="font-bold text-xl sm:text-2xl md:text-3xl text-gray-700" style={{ position: 'relative', zIndex: '1' }}>Liste des Commandes</h1>
            <OrderTable orders={orders} />
        </div>
    );
}

export default OrderPage;
