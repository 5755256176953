import React, { useState, useEffect, memo } from 'react';
import { FaSpinner, FaEye, FaSearch } from "react-icons/fa";
import { motion, useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { productService } from '../api/productService';

const GradientBackgroundAnimation = () => {
    const animationControl = useAnimation();

    useEffect(() => {
        const sequence = async () => {
            await animationControl.start({
                background: [
                    `linear-gradient(120deg, #fbfbfb 0%, #fbfbfb 100%)`,
                ],
            }, {
                duration: 30,
                loop: Infinity,
                ease: "linear"
            });
        };

        sequence();
    }, [animationControl]);

    return (
        <motion.div
            className="fixed top-0 left-0 w-full h-full z-0"
            style={{ pointerEvents: 'none' }}
            animate={animationControl}
        />
    );
};

const ProductTable = memo(() => {
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(9);
    const [displayedProducts, setDisplayedProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');


    const navigate = useNavigate();

    useEffect(() => {
        const loadProducts = async () => {
            setIsLoading(true);
            try {
            const data = await productService.fetchProducts(currentPage, productsPerPage);
            setDisplayedProducts(data['hydra:member'] || []);
            setTotalPages(Math.ceil((data['hydra:totalItems'] || 0) / productsPerPage));
        } catch (error) {
            console.error('Erreur lors de la récupération des produits:', error);
        }
        setIsLoading(false);
    };
    loadProducts();
}, [currentPage, productsPerPage, searchTerm]);

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.1 } }
    };

    const itemVariants = {
        hidden: { y: 10, opacity: 0 },
        visible: { y: 0, opacity: 1 }
    };

    const handleViewDetails = (productUrl) => {
        const productId = productUrl.split('/').pop(); 
        navigate(`/product/${productId}`);
    };

    return (
        <>
            <GradientBackgroundAnimation />
            <div className="container mx-auto p-4 z-10 relative">
                <div className="mb-4 flex space-x-2">
                    <input
                        type="text"
                        placeholder="Rechercher..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="flex-grow appearance-none border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <button
                        onClick={() => setCurrentPage(1)}
                        className="bg-[#272860] hover:bg-[#1e1e50] text-white font-bold py-2 px-4 rounded flex items-center"
                    >
                        <FaSearch className="inline mr-2" />Rechercher
                    </button>
                </div>
                {isLoading ? (
                    <div className="flex justify-center items-center">
                        <FaSpinner className="animate-spin h-8 w-8 mr-3" />
                        Chargement...
                    </div>
                ) : (
                    <motion.div
                        className="bg-white shadow rounded-lg overflow-x-auto"
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                    >
                        <table className="min-w-full whitespace-no-wrap">
                            <thead style={{ backgroundColor: '#272860' }}>
                                <tr className="text-white text-center">
                                    <th className="px-4 py-2">Nom</th>
                                    <th className="px-6 py-2">Prix</th>
                                    <th className="px-4 py-2">SKU</th>
                                    <th className="px-4 py-2">Canal</th>
                                    <th className="px-4 py-2">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedProducts.map((product) => (
                                    <motion.tr
                                        key={product['@id']}
                                        variants={itemVariants}
                                        className="text-center border-b"
                                    >
                                        <td className="px-6 py-4">{product.name}</td>
                                        <td className="px-6 py-4">{product.price}</td>
                                        <td className="px-6 py-4">{product.sku}</td>
                                        <td className="px-6 py-4">{product.channel.name}</td>
                                        <td className="px-6 py-4">
                                            <FaEye
                                                className="inline mr-2 cursor-pointer hover:scale-110 transform transition duration-150"
                                                style={{ backgroundColor: '#272860', padding: '4px', borderRadius: '50%', color: 'white' }}
                                                onClick={() => handleViewDetails(product['@id'])}
                                            />
                                        </td>
                                    </motion.tr>
                                ))}
                            </tbody>
                        </table>
                    </motion.div>
                )}
                <div className="pagination flex justify-center mt-4 space-x-2">
                    <button onClick={() => setCurrentPage(current => Math.max(current - 1, 1))} disabled={currentPage === 1} className="bg-[#272860] hover:bg-[#1e1e50] text-white font-bold py-2 px-4 rounded disabled:opacity-50">Précédent</button>
                    <button onClick={() => setCurrentPage(current => Math.min(current + 1, totalPages))} disabled={currentPage === totalPages} className="bg-[#272860] hover:bg-[#1e1e50] text-white font-bold py-2 px-4 rounded disabled:opacity-50">Suivant</button>
                </div>
            </div>
        </>
    );
});

export default ProductTable;
