const API_URL = "https://127.0.0.1:8000/api";

const getAuthHeaders = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return {
        'Authorization': `Bearer ${user?.token}`,
        'Content-Type': 'application/json'
    };
};

const handleError = async (response) => {
    const errorText = await response.text();
    throw new Error(`${response.status}: ${errorText}`);
};

const getCacheKey = (url) => {
    const user = JSON.parse(localStorage.getItem('user'));
    return `${url}_${user?.id}`;
};

const fetchFromCache = (cacheKey) => {
    const cachedData = localStorage.getItem(cacheKey);
    if (!cachedData) return null;

    const { expiry, data } = JSON.parse(cachedData);
    const now = new Date();

    if (now.getTime() > expiry) {
        localStorage.removeItem(cacheKey);
        return null;
    }

    return data;
};

const cacheData = (cacheKey, data, duration = 3600) => {
    const expiry = new Date().getTime() + (duration * 1000);
    const cachedData = { expiry, data };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

export const productService = {
    fetchProducts: async (page = 1, limit = 9) => {
        const url = `${API_URL}/products?page=${page}&itemsPerPage=${limit}`;

        const cacheKey = getCacheKey(url);
        const cachedData = fetchFromCache(cacheKey);
        if (cachedData) return cachedData;

        const response = await fetch(url, {
            headers: getAuthHeaders(),
            mode: 'cors'
        });
        if (!response.ok) {
            await handleError(response);
        }
        const data = await response.json();
        cacheData(cacheKey, data);

        // Preload next page
        if (page * limit < data['hydra:totalItems']) {
            const nextPageUrl = `${API_URL}/products?page=${page + 1}&itemsPerPage=${limit}`;
            const nextCacheKey = getCacheKey(nextPageUrl);
            fetch(nextPageUrl, {
                headers: getAuthHeaders(),
                mode: 'cors'
            })
            .then(response => response.json())
            .then(nextData => cacheData(nextCacheKey, nextData))
            .catch(error => console.error('Error prefetching next page:', error));
        }

        return data;
    },

    fetchAttributeOptions: async (productId) => {
        const url = `${API_URL}/attribute_options?product=${productId}`;
        const cacheKey = getCacheKey(url);
        const cachedData = fetchFromCache(cacheKey);
        if (cachedData) return cachedData;

        const response = await fetch(url, {
            headers: getAuthHeaders(),
            mode: 'cors'
        });
        if (!response.ok) {
            await handleError(response);
        }
        const data = await response.json();
        cacheData(cacheKey, data['hydra:member'] || []);

        return data['hydra:member'] || [];
    },

    fetchProduct: async (productId) => {
        const url = `${API_URL}/productsDetails/${productId}`;
        const cacheKey = getCacheKey(url);
        const cachedData = fetchFromCache(cacheKey);
        if (cachedData) return cachedData;

        const response = await fetch(url, {
            headers: getAuthHeaders(),
            mode: 'cors'
        });
        if (!response.ok) {
            await handleError(response);
        }
        const data = await response.json();
        cacheData(cacheKey, data);

        return data;
    },

    updateProduct: async (productId, updates) => {
        const response = await fetch(`${API_URL}/products/${productId}`, {
            method: 'PATCH',
            headers: {
                ...getAuthHeaders(),
                'Content-Type': 'application/merge-patch+json',
            },
            body: JSON.stringify(updates),
            mode: 'cors'
        });
        if (!response.ok) {
            await handleError(response);
        }
        return await response.json();
    },
};
